<template>
    <div>
        <span :style="{ width: this.current_percent + '%' }"></span>
    </div>
</template>

<script>

export default {
    props: {
        percent: {
            type: Number,
            default: 0,
            required: true,
        // validator: val => val >= 0 && val <= 100
        }
    },

    data() {
        return {
            current_percent: this.percent
        }
    },

    watch: {
        percent: {
            handler(new_percent) {
                this.current_percent = new_percent;
            }
        }
    }
}
</script>