<template>
  <div>
    <SiteHeader />
    <progress-bar
      class="progress-bar"
      :percent="progress_percent"
    ></progress-bar>

    <mortgage-type
      class="container"
      v-show="step === 1"
      v-on:update="updated('mortgage_type', $event)"
      :type="questions.mortgage_type"
    ></mortgage-type>

    <applicants
      class="container"
      v-show="step === 2"
      v-on:update="updated('applicants', $event)"
      :applicants="questions.applicants"
    ></applicants>

    <questions
      class="container"
      ref="question_component"
      v-show="step === 3"
      v-on:updated="updateQuestionAnswer"
    ></questions>

    <applicant-form
      class="container applicant-form"
      ref="applicant"
      :applicants="this.questions.applicants === 'single' ? 1 : 2"
      v-show="step === 4"
      v-on:updated="applicantUpdate"
    ></applicant-form>

    <!-- <applicant-form
            class="container applicant-form"
            ref="applicant_2"
            :applicant="2"
            v-show="step === 5"
            v-on:updated="applicantUpdate(2, $event)"
        ></applicant-form> -->

    <complete v-show="step === 5" ref="complete"></complete>

    <div class="page-errors" v-if="page_errors">
      <p>There are validation errors on this, or a previous page</p>
      <p>
        Please review the questions and make that each answer you have given is
        valid
      </p>
    </div>

    <div class="container buttons">
      <div class="row">
        <div class="col-12 col-lg-4 offset-lg-4">
          <button class="btn btn-secondary" @click="back" v-show="showBack">
            Back
          </button>
          <button
            class="btn btn-primary"
            @click="next"
            v-show="showContinue"
            v-text="continueText"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/services";
import SiteHeader from "@/components/mortgage/SiteHeader";
import ProgressBar from "@/components/mortgage/ProgressBar";
import MortgageType from "@/components/mortgage/MortgageType.vue";
import Applicants from "@/components/mortgage/Applicants.vue";
import Questions from "@/components/mortgage/Questions.vue";
import ApplicantForm from "@/components/mortgage/ApplicantForm.vue";
import Complete from "@/components/mortgage/Complete.vue";
import Steps from "@/views/steps.js";

export default {
  props: [],

  components: {
    SiteHeader,
    ProgressBar,
    MortgageType,
    Applicants,
    Questions,
    ApplicantForm,
    Complete,
  },

  data() {
    return {
      debug: false,
      validation: {},
      step: 1,
      furthest_step: 1,
      total_steps: 6,
      progress_percent: 0,
      questions: {
        mortgage_type: null,
        applicants: null,
      },
      applicant_1: {},
      applicant_2: {},
      showContinue: false,
      showBack: false,
      continueText: "Continue",
      page_errors: false,
      isSubmitting: true,
      urls: {
        1: "start",
        2: "applicants",
        3: "questions",
        4: "details",
        5: "complete",
      },
      types: [
        "first-home",
        "move-home",
        "remortgage",
        "buy-to-let"
      ],
      applicants: [
        "single",
        "joint"
      ]
    };
  },
  methods: {
    forceValidate: function (questions) {
      var errors = 0;
      this.page_errors = false;

      questions.forEach((question) => {
        if (!question.visible) {
          return;
        }

        var response = this.validate({
          key: question.key,
          value: question.value,
        });

        if (!response.valid) {
          question.error = response.error;
          errors++;
        }
      });

      return errors;
    },
    back: function () {
      this.goto(this.step - 1);
    },
    next: function () {
      if (!this.debug) {
        var errors;
        if (this.step === Steps.QUESTIONS) {
          // On Questions, going to Applicant 1
          this.page_errors = false;
          errors = this.forceValidate(this.$refs.question_component.questions);

          if (errors > 0) {
            console.error(`There are ${errors} errors on this page`);
            this.page_errors = true;
            return;
          }
        } else if (this.step === Steps.APPLICANT) {
          this.page_errors = false;
          errors = this.forceValidate(this.$refs.applicant.questions_1);

          if (errors > 0) {
            console.error(`There are ${errors} errors on this page`);
            this.page_errors = true;
            return;
          }
        }
      }

      this.goto(this.step + 1);
    },
    goto: function (step, skip_history = false) {
      console.log(step)
      if (step < 1 || step > this.total_steps) {
        console.error(`Step ${step} does not exist`);
      }

      this.step = step;
      this.progress(step);

      if (step > this.furthest_step) {
        this.furthest_step = step;
      }

      this.showHideButtons();

      window.scrollTo(0, 0);
      this.continueText = "Continue";

      if (this.step === Steps.QUESTIONS) {
        if (
          this.questions.mortgage_type !== this.$refs.question_component.type ||
          this.questions.applicants !== this.$refs.question_component.applicants
        ) {
          this.$refs.question_component.getQuestions(
            this.questions.mortgage_type,
            this.questions.applicants
          );
        }
      } else if (
        this.step === Steps.APPLICANT &&
        !this.$refs.applicant.questions_1.length
      ) {
        this.$refs.applicant.getQuestions(1);

        if (this.questions.applicants === "joint") {
          this.$refs.applicant.getQuestions(2);
        }
      } else if (this.step === Steps.COMPLETE) {
        this.submitData();
        this.isSubmitting = true;
        this.showContinue = false;
        this.showBack = false;
      }

      if (!skip_history) {
        history.pushState(
          { page: this.step },
          null,
          "/" + this.urls[this.step]
        );
      }
    },
    showHideButtons() {
      this.showBack = false;
      this.showContinue = false;

      if (this.step > Steps.TYPE) {
        this.showBack = true;
      }

      if (this.step < this.furthest_step) {
        this.showContinue = true;
      }

      if (!this.showContinue && this.step >= Steps.QUESTIONS) {
        this.showContinue = true;
      }
    },
    progress: function () {
      var percent_per_step = (1 / this.total_steps) * 100;

      if (this.questions.applicants === "single") {
        percent_per_step = (1 / (this.total_steps - 1)) * 100;
      }

      this.progress_percent = this.step * percent_per_step;
    },
    updateQuestionAnswer: function (event) {
      this.$set(this.questions, event.key, event.value);
    },
    updated: function (key, event) {
      this.$set(this.questions, key, event.value);

      this.goto(event.next.page);
    },
    applicantUpdate: function (event) {
      console.log("APP", event);
      let applicant = event.applicant;
      this["applicant_" + applicant][event.key] = event.value;

      this.$refs.applicant["questions_" + applicant].forEach((question) => {
        if (question.key === event.key) {
          question.value = event.value;
        }
      });
    },
    loadValidation: function () {
      var that = this;

      http.get("forms/validation").then(({ data }) => {
        data.forEach((item) => {
          let rules = {};

          for (const [key, value] of Object.entries(item.rules)) {
            rules[key] = value;
          }

          that.validation[item.field] = rules;
        });
      });
    },
    validate: function (event) {
      var rules = this.validation[event.key];

      if (!rules) {
        return { valid: true };
      }

      if (rules.required && !event.value) {
        return { valid: false, error: rules.required.error };
      }

      // Only perform further validation tests when leaving the field
      if (!event.blur) {
        return { valid: true };
      }

      if (rules.min && parseInt(event.value) < rules.min.value) {
        return {
          valid: false,
          error: rules.min.error.replace(/:value/gi, rules.min.value),
        };
      }

      if (rules.max && parseInt(event.value) > rules.max.value) {
        return {
          valid: false,
          error: rules.max.error.replace(/:value/gi, rules.max.value),
        };
      }

      if (rules.min_length && event.value.length < rules.min_length.value) {
        return {
          valid: false,
          error: rules.min_length.error.replace(
            /:value/gi,
            rules.min_length.value
          ),
        };
      }

      if (rules.max_length && event.value.length < rules.max_length.value) {
        return {
          valid: false,
          error: rules.max_length.error.replace(
            /:value/gi,
            rules.max_length.value
          ),
        };
      }

      return { valid: true };
    },
    loadCorrectPage: function () {
      let path = window.location.pathname.substring(1).split("/")[0];

      let pages = {
        start: 1,
        applicants: 2,
        questions: 3,
        details: 4,
        details2: 5,
        account: 6,
      };

      if (pages[path] && pages[path] <= this.furthest_step) {
        this.step = pages[path];
      } else if (pages[path] && pages[path] > this.furthest_step) {
        this.step = this.furthest_step;

        var url = "";

        for (var i in pages) {
          if (pages[i] === this.furthest_step) {
            url = i;
            break;
          }
        }

        history.pushState({ page: this.furthest_step }, null, "/" + url);
      }
    },
    submitData: function () {
      // Check if likely to succeed
      var questions = this.questions;
      var earnings = 0;

      if (questions.earnings_per_year) {
        earnings += questions.earnings_per_year;
      }

      if (questions.other_earnings_per_year) {
        earnings += questions.other_earnings_per_year;
      }

      var low_earnings = earnings * 6 < questions.value;
      var low_deposit = questions.deposit < (questions.value / 100) * 5;

      if (low_earnings || low_deposit) {
        this.$refs.complete.status = "failed";
        this.$refs.complete.submitting = false;
        this.isSubmitting = false;
        return;
      }

      var question_items = this.$refs.question_component.questions;
      var questions_applicant_1 = this.$refs.applicant.questions_1;
      var questions_applicant_2 = this.$refs.applicant.questions_2;
      questions = {};
      var applicant_1 = {};
      var applicant_2 = {};

      for (var i in question_items) {
        let key = question_items[i].key;
        let safe_key = key.replace(/-/g, "_");
        questions[safe_key] = this.questions[key];
      }

      for (var x in questions_applicant_1) {
        let key = questions_applicant_1[x].key;
        let safe_key = key.replace(/-/g, "_");
        applicant_1[safe_key] = this.applicant_1[key];
      }

      for (var y in questions_applicant_2) {
        let key = questions_applicant_2[y].key;
        let safe_key = key.replace(/-/g, "_");
        applicant_2[safe_key] = this.applicant_2[key];
      }

      let data = {
        mortgage_type: this.questions.mortgage_type,
        applicants: this.questions.applicants,
        questions,
        applicant_1,
        applicant_2: this.applicants === "joint" ? applicant_2 : {},
      };

      // var form_data = JSON.stringify(data);
      this.page_errors = false;

      http
        .post("forms/submit", data)
        .then(({ data }) => {
          if (data.received) {
            this.showBack = false;
            this.showContinue = false;
          } else {
            this.page_errors = true;
            this.back();
          }

          this.$refs.complete.status = "success";
          this.$refs.complete.generateUrl();

          this.isSubmitting = false;
        })
        .catch((error) => {
          console.log(error);
          this.isSubmitting = false;
        });
    },
    handleParams() {
      let applicationType = this.$route.params.applicationType
      let applicants = this.$route.params.applicants
      let validateType = this.types.includes(applicationType)
      let validateApplicants = this.applicants.includes(applicants)

      if (validateType) {
        this.questions.mortgage_type = applicationType
        if (validateType && !validateApplicants) this.goto(2)
      }
      if (validateApplicants) {
        this.questions.applicants = applicants
        this.goto(3)
      }
    }
  },
  mounted() {
    this.progress();
    this.loadValidation();
    this.loadCorrectPage();
    this.handleParams()

    var that = this;

    window.onpopstate = function (event) {
      if (typeof event.state.page !== "undefined") {
        that.goto(event.state.page, true);
      }
    };

    if (this.step === Steps.TYPE) {
      history.replaceState({ page: 1 }, null, "/start");
    }
  },
};
</script>

<style lang="scss">
@import "assets/scss/style.scss";
</style>
