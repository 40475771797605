import Vue from 'vue'
import Router from 'vue-router'

import App from '@/App'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/start/:applicationType',
            name: 'startWithApplicationType',
            component: App,
        },
        {
            path: '/start/:applicationType/:applicants',
            name: 'startWithApplicationTypeAndApplicants',
            component: App,
        }
    ]
})
