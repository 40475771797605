<template>
  <div>
    <h2>Is this a single or joint mortgage?</h2>

    <div class="selection-buttons">
      <div v-for="(option, index) in options" :key="index">
        <button
          class="selection-button"
          :class="{ selected: value === index }"
          @click="select"
          :data-value="index"
          :style="{
            'background-image':
              'url(' + require(`@/assets/images/${index}.svg`) + ')',
          }"
        >
          <strong>{{ option.text }}</strong>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['applicants'],
  watch: {
    applicants: {
      immediate: true,
      deep: true,
      handler (value) {
        if (value) {
          this.value = value
        }
      }
    }
  },
  data() {
    return {
      dev: null,
      value: null,
      options: {
        single: {
          text: "Single",
          icon: "single.svg",
        },
        joint: {
          text: "Joint",
          icon: "joint.svg",
        },
      },
    };
  },
  methods: {
    select: function (event) {
      var payload = {
        value: event.currentTarget.getAttribute("data-value"),
        next: {
          page: 3,
          url: "/questions",
        },
      };

      this.value = payload.value;

      this.$emit("update", payload);
    },
  },
  mounted() {
    this.dev = process.env.NODE_ENV === "development";
  },
};
</script>
