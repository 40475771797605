<template>
    <div>
        <div class="row">
            <div class="col-12">

                <h3 class="label">{{ question.text }}</h3>

                <aside v-if="question.help">{{ question.help }}</aside>

                <div class="form-field">
                    <div
                        :is="question.type + '-input'"
                        :error="question.error"
                        v-on:updated="update"
                        v-on:click-outside="update"
                        :question="question"
                    ></div>

                    <aside
                        class="error"
                        v-if="question.error && question.error !== '[NO_ERROR]'"
                        v-html="question.error"
                    ></aside>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['question'],

    components: {
        'text-input': () => import('./Inputs/TextInput.vue'),
        'phone-input': () => import('./Inputs/PhoneInput.vue'),
        'email-input': () => import('./Inputs/EmailInput.vue'),
        'radio-input': () => import('./Inputs/RadioInput.vue'),
        'date-input': () => import('./Inputs/DateInput.vue'),
        'address-input': () => import('./Inputs/AddressInput.vue'),
        'currency-input': () => import('./Inputs/CurrencyInput.vue'),
    },

    data() {
        return {
            options: [],
            value: null,
        }
    },

    methods: {
        update: function (event) {
            this.value = event.value;

            let data = {
                key: this.question.key,
                value: this.value,
                blur: event.blur,
                dependants: this.question.show
            }

            this.$emit('updated', data);
        }
    },
    
}
</script>