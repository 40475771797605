<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-7">
        <div class="row">
          <div class="col-12">
            <question
              class="question default"
              v-for="(question, index) in questions"
              :key="index"
              :question="question"
              v-on:updated="update"
              v-show="question.visible"
            ></question>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 d-md-block d-none offset-1">
        <div class="steps-wrapper">
          <div class="steps-card">
            <div class="steps-card__image steps-card__imagetop"></div>
            <div class="steps-card__stepnumber">STEP 1</div>
            <div class="steps-card__step-description">
              Answer a few questions about your situation
            </div>
            <div class="steps-card__stepnumber">STEP 2</div>
            <div class="steps-card__step-description">
              Free consultation with one of our mortgage experts
            </div>
            <div class="steps-card__steps-stats clearfix">
              <div class="one-third">
                <div class="stat">5/9</div>
                <div class="stat-value">QUESTIONS</div>
              </div>
              <div class="two-third no-border">
                <div class="stat">10%</div>
                <div class="stat-value">COMPLETED</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/services";
import Question from "./Question.vue";

export default {
  components: {
    Question,
  },

  data() {
    return {
      questions: [],
      type: null,
      applicants: null,
    };
  },
  methods: {
    clearValidationError: function (event) {
      this.showValidationError(event, null);
    },
    showValidationError: function (event, error) {
      var question = null;

      this.questions.forEach((q) => {
        if (q.key === event.key) {
          question = q;
        }
      });

      if (question) {
        question.error = error;
      }
    },
    update: function (event) {
      var response = this.$parent.validate(event);
      this.clearValidationError(event);

      if (!response.valid) {
        this.showValidationError(event, response.error);
      }

      this.$emit("updated", event);

      this.questions.forEach((question) => {
        if (question.key === event.key) {
          question.value = event.value;
        }
      });

      var dependants = event.dependants;

      if (typeof dependants === "object") {
        for (const [key, value] of Object.entries(dependants)) {
          var show = value === event.value;

          this.questions.forEach((question, index) => {
            if (question.key === key) {
              question.visible = show;
              this.$set(this.questions, index, question);
            }
          });
        }
      }
    },
    getQuestions: function (type, applicants) {
      var that = this;

      http
        .get("forms/questions", { params: { type, applicants } })
        .then(({ data }) => {
          that.questions = [];

          var saved_data = null;

          if (window.localStorage.getItem("progress")) {
            saved_data = JSON.parse(window.localStorage.getItem("progress"));
          }

          data.forEach((question) => {
            question.error = null;

            if (saved_data) {
              question.value = saved_data.questions[question.key];
            }

            that.questions.push(question);
          });

          that.type = type;
          that.applicants = applicants;
        });
    },
  },
  mounted() {},
};
</script>

<style></style>
