<template>
    <div>
        <header>
            <a href="/">
                <img alt="logo" src="https://www.dm-mortgages.co.uk/wp-content/uploads/2020/10/DM-Logo-1.png">
            </a>
        </header>
    </div>
</template>

<script>

export default {
    props: [],
}
</script>