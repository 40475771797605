import axios from 'axios'

export const http = axios.create({
    baseURL:
        process.env.NODE_ENV === 'production'
            ? 'https://dm-kernal-backend.onrender.com/factfind/api/initial/'
            : 'http://127.0.0.1:8000/factfind/api/initial/',
    xhrFields: {
        withCredentials: true,
    },
    headers: {
        'Content-Type': 'application/json',
    },
})

const errorInterceptor = (error) => {
    switch (error.response.status) {
        case 401: // authentication error
            break
        case 403: // Incorrect permission
            break
        default:
            console.error('server error')
    }
    return Promise.reject(error)
}

const responseInterceptor = (response) => {
    switch (response.status) {
        case 200:
            break
        default:
            break
    }
    return response
}

http.interceptors.response.use(responseInterceptor, errorInterceptor)
