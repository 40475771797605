<template>
  <div>
    <transition name="fade">
      <div class="submitting" v-if="this.$parent.isSubmitting">
        <div class="box">
          <h2>Just a moment...</h2>
          <p>Synchronising the mortgage advisor diaries.</p>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="failed" v-if="status === 'failed'">
        <h2>Sorry!</h2>
        <p>
          Based on the information supplied you are unlikely to be able to
          secure the mortgage requested.
        </p>
        <p>
          If you still wish to speak with a specialist please call
          <a href="tel:01782336222">01785 336222</a> or email
          <a href="mailto:enquiries@dunham-mccarthy.co.uk"
            >enquiries@dunham-mccarthy.co.uk</a
          >.
        </p>
      </div>
    </transition>

    <transition name="fade">
      <div class="success" v-if="status === 'success'">
        <iframe
          id="booking-form"
          :src="appointment_url"
          width="100%"
          frameBorder="0"
          scrolling="yes"
        ></iframe>
      </div>
    </transition>

    <div class="info-bar">
      <div class="info-bar--container">
        <button class="btn btn-back btn-secondary" @click="back">Back</button>

        <button class="btn btn-back btn-primary" @click="startAgain">
          Start Again
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: null,
      appointment_url: null,
      appointment_base_url: "https://app.acuityscheduling.com/schedule.php",
      appointment_params: {
        owner: 14658836,
        appointmentType: 10545964,
      },
    };
  },

  methods: {
    back: function () {
      this.$parent.goto(this.$parent.step - 1);
    },
    startAgain: function () {
      window.location.href = "/start";
    },
    generateUrl: function () {
      let applicant = this.$parent.applicant_1;

      this.appointment_params = {
        ...this.appointment_params,
        ...{
          firstName: applicant.first_name,
          lastName: applicant.last_name,
          email: applicant.email,
          phone: applicant.phone,
        },
      };

      var url = this.appointment_base_url + "?";

      Object.entries(this.appointment_params).forEach((item) => {
        url += item[0] + "=" + item[1] + "&";
      });

      this.appointment_url = url.substring(0, url.length - 1);
    },
  },
};
</script>
