<template>
    <div>
        <h2>What type of mortgage do you require?</h2>

        <div class="selection-buttons">
            <div v-for="(option, index) in options" :key="index">
                <button
                    class="selection-button"
                    :class="{ selected: value === index }"
                    @click="select"
                    :data-value="index"
                    :style="{ 'background-image': 'url(' + require(`@/assets/images/${index}.svg`) + ')' }"
                >
                    <strong>{{ option.text }}</strong>                
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['type'],
    watch: {
      type: {
        immediate: true,
        deep: true,
        handler (value) {
          if (value) {
            this.value = value
          }
        }
      }
    },
    data() {
        return {
            dev: null,
            value: null,
            options: {
                'first-home': {
                    text: 'First Home',
                    icon: 'first-home',
                },
                'move-home': {
                    text: 'Move Home',
                    icon: 'move-home',
                },
                'remortgage': {
                    text: 'Remortgage',
                    icon: 'remortgage',
                },
                'buy-to-let': {
                    text: 'Buy To Let',
                    icon: 'buy-to-let',
                }
            }
        }
    },
    methods: {
        select: function (event) {
            var payload = {
                value: event.currentTarget.getAttribute('data-value'),
                next: {
                    page: 2,
                    url: '/applicants',
                }
            }

            this.value = payload.value;

            this.$emit('update', payload)
        }
    },
    mounted() {
        this.dev = process.env.NODE_ENV === 'development'
    },
}
</script>
