<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-7">
        <div class="row">
          <div class="col-12">
            <applicant-question
              class="question"
              v-for="(question, index) in questions_1"
              :key="index"
              :question="question"
              :applicant="1"
              v-on:updated="update"
              v-show="question.visible"
            ></applicant-question>
          </div>

          <div class="col-12" v-if="this.applicants === 2">
            <applicant-question
              class="question"
              v-for="(question, index) in questions_2"
              :key="index"
              :question="question"
              :applicant="2"
              v-on:updated="update"
              v-show="question.visible"
            ></applicant-question>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 d-md-block d-none offset-1">
        <div class="steps-wrapper">
          <div class="steps-card">
            <div class="steps-card__image steps-card__imagetop"></div>
            <div class="steps-card__stepnumber">STEP 1</div>
            <div class="steps-card__step-description">
              Answer a few questions about your situation
            </div>
            <div class="steps-card__stepnumber">STEP 2</div>
            <div class="steps-card__step-description">
              Free consultation with one of our mortgage experts
            </div>
            <div class="steps-card__steps-stats clearfix">
              <div class="one-third">
                <div class="stat">5/9</div>
                <div class="stat-value">QUESTIONS</div>
              </div>
              <div class="two-third no-border">
                <div class="stat">
                  {{ this.$parent.progress_percent.toFixed(0) + "%" }}
                </div>
                <div class="stat-value">COMPLETED</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/services";

export default {
  props: ["applicants"],

  components: {
    "applicant-question": () => import("./ApplicantQuestion.vue"),
  },

  data() {
    return {
      questions_1: [],
      questions_2: [],
    };
  },
  methods: {
    clearValidationError: function (event) {
      this.showValidationError(event, null);
    },
    showValidationError: function (event, error) {
      var question = null;

      this.questions.forEach((q) => {
        if (q.key === event.key) {
          question = q;
        }
      });

      if (question) {
        question.error = error;
      }
    },
    update: function (event) {
      console.log("AF", event);
      var response = this.$parent.validate(event);

      this.clearValidationError(event);

      if (!response.valid) {
        this.showValidationError(event, response.error);
      }

      this.$emit("updated", event);

      this.questions.forEach((question) => {
        if (question.key === event.key) {
          question.value = event.value;
        }
      });
    },
    getQuestions: function (applicant) {
      var that = this;

      http
        .get("/forms/applicant/" + applicant)
        .then(({ data }) => {
          that.questions = [];

          data.forEach((question) => {
            question.error = null;
            question.value = null;
            that["questions_" + applicant].push(question);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {},
};
</script>
